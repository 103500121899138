import * as React from 'react';
import { IFormContainerProps } from '../FormContainer.types';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import { translations } from '../constants';
import { FormContainerRoot } from './shared/FormContainerRoot';
import styles from './styles/FormContainer.scss';
import { Honeypot } from './Honeypot';

const FormContainerSkin: React.FC<IFormContainerProps> = props => {
  const {
    id,
    translate,
    meshProps,
    onSubmit,
    onHoneypotChange,
    honeypotValue,
    children,
  } = props;

  const meshContainerProps = {
    id,
    ...meshProps,
    children,
  };

  return (
    <FormContainerRoot id={id} className={styles.root} onSubmit={onSubmit}>
      <MeshContainer {...meshContainerProps} />
      <Honeypot
        value={honeypotValue}
        label={translate!(
          translations.FORM_NAMESPACE,
          translations.HONEYPOT_LABEL_KEY,
          translations.HONEYPOT_LABEL_DEFAULT,
        )}
        onChange={value => (onHoneypotChange ? onHoneypotChange(value) : null)}
      />
    </FormContainerRoot>
  );
};

export default FormContainerSkin;
