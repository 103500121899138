import * as React from 'react';
import styles from './styles/Honeypot.scss';

export type IHoneypotProps = {
  value?: string;
  onChange: (value: string) => void;
  className?: string;
  label: string;
};

const Honeypot: React.FC<IHoneypotProps> = props => {
  const { onChange, value, label } = props;

  return (
    <div className={styles.honeypotWrapper}>
      <label>
        {label}
        <input
          onChange={e => onChange(e.target.value)}
          type="text"
          name="message"
          autoComplete="nada"
          value={value}
          tabIndex={-1}
        />
      </label>
    </div>
  );
};

export { Honeypot };
